/* eslint-disable @typescript-eslint/no-explicit-any */
import { z } from "zod";
import { createFormFieldAndState } from "./createFormFieldAndState";
import { removeChildren } from "@tedivo/tedivo-dom-helpers";
export class TedivoForm {
    constructor({ fields, onSubmit, formValidator, submitButton, formProps, hiddenData, logValidationResult = false, }) {
        var _a;
        this.myId = `id-${Math.round(Math.random() * 10000)}`;
        /** Include all controls, titles too */
        this.formControls = [];
        /** Only RecordsOfControls (no titles) */
        this.formControlsByName = {};
        this.hasSubmittedAlready = false;
        this.hiddenData = undefined;
        this.logValidationResult = false;
        this.addSectionsAndFieldsToForm = (fields) => {
            var _a;
            let sectionHolder = undefined;
            let autoFocusOnFirstInput = !!((_a = this.formProps) === null || _a === void 0 ? void 0 : _a.autoFocusOnFirstInput);
            const setAutofocus = (field) => {
                field.setAttribute("autofocus", "");
                setTimeout(() => {
                    try {
                        if (field !== null && (field === null || field === void 0 ? void 0 : field.focus))
                            field.focus();
                    }
                    catch (e) {
                        //Do nothing
                    }
                }, 100);
            };
            const addNewField = (fieldData) => {
                var _a, _b;
                if (!fieldData)
                    return undefined;
                const field = createFormFieldAndState({
                    fieldProps: fieldData,
                    formProps: this.formProps,
                    formControlsByName: this.formControlsByName,
                });
                if (!(field === null || field === void 0 ? void 0 : field.formField) || !(field === null || field === void 0 ? void 0 : field.stateRecord))
                    return undefined; // -->>> Fast Return!
                if (fieldData.type === "title" && fieldData.createSection) {
                    sectionHolder = document.createElement("section");
                    this.form.appendChild(sectionHolder);
                }
                this.formControls.push(field.stateRecord);
                if (field.stateRecord.name &&
                    field.stateRecord.type !== "title" &&
                    field.stateRecord.type !== "node") {
                    this.formControlsByName[field.stateRecord.name] = field.stateRecord;
                    // Event listeners begin ---------------------------------
                    field.formField.addEventListener("sl-change", this.onChangeValue, false);
                    if ((_b = (_a = field.stateRecord) === null || _a === void 0 ? void 0 : _a.fieldProps) === null || _b === void 0 ? void 0 : _b.inputListener) {
                        field.formField.addEventListener("sl-input", (ev) => {
                            this.onChangeValue(ev);
                        });
                    }
                    if (field.stateRecord.type === "numberWithUnits") {
                        field.formField.addEventListener("keyup", (ev) => {
                            if (ev.key === "Enter")
                                this.submitForm({});
                        }, false);
                    }
                    // Event listeners end -----------------------------------
                }
                if (autoFocusOnFirstInput &&
                    (field.stateRecord.type === "textBox" ||
                        field.stateRecord.type === "textArea" ||
                        field.stateRecord.type === "number" ||
                        field.stateRecord.type === "numberWithUnits")) {
                    autoFocusOnFirstInput = false;
                    setAutofocus(field.formField);
                }
                return field.formField;
            };
            fields.forEach((fA) => {
                if (fA === undefined)
                    return;
                // Row holder
                const rowHolder = document.createElement("div");
                if (Array.isArray(fA)) {
                    fA.forEach((fB) => {
                        const fieldNode = addNewField(fB);
                        if (fieldNode)
                            rowHolder.appendChild(fieldNode);
                    });
                }
                else {
                    const fieldNode = addNewField(fA);
                    if (fieldNode)
                        rowHolder.appendChild(fieldNode);
                }
                rowHolder.className = `form-fields-row ${!Array.isArray(fA) ? fA.type : ""} ${(Array.isArray(fA)
                    ? fA.map((fB) => (fB === null || fB === void 0 ? void 0 : fB.holderClassName) || "").join(" ")
                    : fA.holderClassName) || ""}`;
                if (sectionHolder)
                    sectionHolder.appendChild(rowHolder);
                else
                    this.form.appendChild(rowHolder);
            });
        };
        this.onChangeValue = (ev) => {
            const target = ev.target;
            const value = target.value;
            const name = target.id;
            const stateRecord = this.formControlsByName[name];
            if (!stateRecord)
                return;
            stateRecord.value = getValue(stateRecord, value);
            if (this.hasSubmittedAlready) {
                this.execValidation([], false);
            }
            if (this.onDataChange) {
                this.onDataChange(this.getValues(), name);
            }
        };
        this.submitForm = (ev) => {
            if (ev.preventDefault)
                ev.preventDefault();
            this.doSubmitForm();
            return false;
        };
        this.doSubmitForm = () => {
            this.hasSubmittedAlready = true;
            const validationResult = this.execValidation();
            if (validationResult.success) {
                const data = Object.assign(Object.assign({}, this.hiddenData), validationResult.data);
                this.onSubmit(data);
            }
            return validationResult;
        };
        this.focusOnFirstElement = (delay = 500) => {
            const fieldNames = Object.keys(this.formControlsByName);
            if (!fieldNames.length)
                return;
            const formControl = this.formControlsByName[fieldNames[0]];
            const field = formControl === null || formControl === void 0 ? void 0 : formControl.field;
            if (field) {
                if (delay) {
                    setTimeout(() => field.focus(), delay);
                }
                else {
                    field.focus();
                }
            }
        };
        this.form = document.createElement("form");
        this.form.className = `tedivo-form ${(_a = formProps === null || formProps === void 0 ? void 0 : formProps.className) !== null && _a !== void 0 ? _a : ""}`;
        this.form.method = "POST";
        this.form.noValidate = true;
        this.form.id = (formProps === null || formProps === void 0 ? void 0 : formProps.id) || this.myId;
        this.formProps = formProps;
        this.formValidator = formValidator;
        this.hiddenData = hiddenData;
        this.logValidationResult = logValidationResult || false;
        this.onSubmit = onSubmit;
        this.addSectionsAndFieldsToForm(fields);
        if (!isElementContainedInParent(submitButton, this.form)) {
            const hiddenSubmit = document.createElement("input");
            hiddenSubmit.type = "submit";
            hiddenSubmit.hidden = true;
            this.form.appendChild(hiddenSubmit);
        }
        this.form.onsubmit = this.submitForm;
    }
    setFields(fields) {
        removeChildren(this.form);
        this.addSectionsAndFieldsToForm(fields);
        return this;
    }
    setFormValidator(newValidator) {
        this.formValidator = newValidator;
        return this;
    }
    markValidFields(validationResult, goToNextError = true) {
        const validationResultError = validationResult;
        const issues = validationResultError.error
            ? validationResultError.error.issues
            : [];
        const issuesByPath = issues.reduce((acc, v) => {
            const n = String(v.path[0]);
            acc[n] = v;
            return acc;
        }, {});
        const fieldNames = Object.keys(this.formControlsByName);
        let firstError = undefined;
        fieldNames.forEach((fc) => {
            const formControl = this.formControlsByName[fc];
            const field = formControl === null || formControl === void 0 ? void 0 : formControl.field;
            if (formControl && field) {
                const issue = issuesByPath[formControl.name];
                if (issue) {
                    // has error
                    if (!field.classList.contains("has-error"))
                        field.classList.add("has-error");
                    if (issue.code === z.ZodIssueCode.custom) {
                        if (field.helpText !== undefined) {
                            field.helpText = issue.message;
                        }
                    }
                    if (!firstError && formControl.type !== "file")
                        firstError = field;
                }
                else {
                    // no error
                    field.classList.remove("has-error");
                    if (field.helpText) {
                        const originalHelpText = field.dataset.helpText;
                        field.helpText = originalHelpText;
                    }
                }
            }
        });
        if (firstError && goToNextError) {
            const field = firstError;
            const formControl = this.formControlsByName[field.id];
            field.focus();
            if (formControl &&
                (formControl.type === "number" ||
                    formControl.type === "numberWithUnits" ||
                    formControl.type === "checkbox" ||
                    formControl.type === "textBox") &&
                !formControl.props.inputListener) {
                field.select();
            }
        }
    }
    getFormControlsByName() {
        return Object.assign({}, this.formControlsByName);
    }
    getAllFormNodes() {
        return this.formControls;
    }
    getValues() {
        const fieldNames = Object.keys(this.formControlsByName);
        const values = fieldNames.reduce((acc, v) => {
            const st = this.formControlsByName[v];
            if (!st)
                return acc;
            acc[v] = getValue(st, st.value);
            return acc;
        }, {});
        return values;
    }
    setValue(key, value) {
        var _a;
        const field = this.formControlsByName[key];
        if (field) {
            const defaultValue = field.props.defaultValue;
            const finalValue = value !== null && value !== void 0 ? value : defaultValue;
            field.value = finalValue;
            field.field.value = finalValue;
        }
        else {
            if (this.hiddenData !== undefined && ((_a = this.hiddenData) === null || _a === void 0 ? void 0 : _a[key]) !== undefined)
                this.hiddenData = Object.assign(Object.assign({}, this.hiddenData), { [key]: value });
        }
        return this;
    }
    /** Execs validation over all fields but marks ALL or ONLY THOSE passed as argument */
    execValidation(fields = [], goToNextError = true) {
        const mergedValues = Object.assign(Object.assign({}, this.hiddenData), this.getValues());
        const validationResult = this.formValidator.safeParse(mergedValues);
        if (this.logValidationResult)
            console.log({
                hidden: this.hiddenData,
                values: mergedValues,
                validationResult,
            });
        // --- All fields
        if (fields.length === 0) {
            this.markValidFields(validationResult, goToNextError);
            return validationResult; // -->>> Fast Return!
        }
        // --- Only some fields.
        // A) All is ok
        if (validationResult.success) {
            return validationResult; // -->>> Fast Return!
        }
        // B) Some fields are wrong
        const error = validationResult.error;
        // B.1) Select only those errors that are in the fields array
        const subsetErrors = error.errors.reduce((acc, issue) => {
            const isChecked = issue.path.some((p) => fields.indexOf(p) >= 0);
            if (isChecked)
                acc.push(issue);
            return acc;
        }, []);
        // B.2) Mark only those fields
        this.markValidFields(Object.assign(Object.assign({}, validationResult), { error: Object.assign(Object.assign({}, error), { errors: subsetErrors }) }), goToNextError);
        return validationResult;
    }
}
function isElementContainedInParent(e, parent) {
    let p = e.parentElement;
    while (p !== null) {
        if (p === parent)
            return true;
        p = p.parentElement;
    }
    return false;
}
export function createSlOption({ value, label, disabled, }) {
    const opt = document.createElement("sl-option");
    opt.value = String(value);
    opt.innerHTML = label;
    if (disabled)
        opt.disabled = true;
    return opt;
}
function getValue(stateRecord, value) {
    if (stateRecord.type === "number" || stateRecord.type === "numberWithUnits") {
        if (value === undefined || value === "") {
            return undefined;
        }
        else {
            return Number(value);
        }
    }
    else if (stateRecord.type === "date") {
        if (value === undefined || value === "") {
            return undefined;
        }
        else if (typeof value === "string") {
            return new Date(value);
        }
        else {
            return value;
        }
    }
    else if (stateRecord.type === "checkbox") {
        if (value === undefined || value === "") {
            return undefined;
        }
        else if (stateRecord.isNumericEnum) {
            return value ? 1 : 0;
        }
        else {
            return Boolean(value);
        }
    }
    else {
        return value;
    }
}
