/* eslint-disable @typescript-eslint/no-explicit-any */

import { IField, IFields, ISlOption } from "../types/IField";
import { Schema, ZodIssue, z } from "zod";

import { IFieldTypesValues } from "../types/IFieldsTypes";
import { IFormProps } from "../types/IFormProps";
import { ITedivoFormProps } from "../types/ITedivoFormProps";
import { ITedivoFormRecord } from "../types/controls/ITedivoFormRecord";
import { ITedivoFormRecordOfControl } from "../types/controls/ITedivoFormRecordOfControl";
import { ITedivoFormRecordOfControlByName } from "../types/controls/ITedivoFormRecordOfControlByName";
import { IValuesByName } from "../types/IValuesByName";
import SlOption from "@shoelace-style/shoelace/dist/components/option/option";
import { TOnSubmitFn } from "../types/TOnSubmitFn";
import { createFormFieldAndState } from "./createFormFieldAndState";
import { removeChildren } from "@tedivo/tedivo-dom-helpers";

export class TedivoForm<TFormSchema extends Record<string, any>> {
  public readonly form: HTMLFormElement;
  public readonly myId = `id-${Math.round(Math.random() * 10000)}`;

  private onSubmit: TOnSubmitFn<TFormSchema>;

  /** Include all controls, titles too */
  private formControls: ITedivoFormRecord<TFormSchema>[] = [];
  /** Only RecordsOfControls (no titles) */
  private formControlsByName: ITedivoFormRecordOfControlByName<TFormSchema> =
    {} as ITedivoFormRecordOfControlByName<TFormSchema>;

  private formValidator: Schema<TFormSchema>;
  private formProps?: IFormProps;
  private hasSubmittedAlready = false;
  private hiddenData: Partial<TFormSchema> | undefined = undefined;

  public onDataChange?: (
    values: TFormSchema,
    nameChanged?: keyof TFormSchema,
  ) => void;

  public logValidationResult = false;

  constructor({
    fields,
    onSubmit,
    formValidator,
    submitButton,
    formProps,
    hiddenData,
    logValidationResult = false,
  }: ITedivoFormProps<TFormSchema>) {
    this.form = document.createElement("form");
    this.form.className = `tedivo-form ${formProps?.className ?? ""}`;
    this.form.method = "POST";
    this.form.noValidate = true;
    this.form.id = formProps?.id || this.myId;

    this.formProps = formProps;
    this.formValidator = formValidator;
    this.hiddenData = hiddenData;
    this.logValidationResult = logValidationResult || false;
    this.onSubmit = onSubmit;

    this.addSectionsAndFieldsToForm(fields);

    if (!isElementContainedInParent(submitButton, this.form)) {
      const hiddenSubmit = document.createElement("input");
      hiddenSubmit.type = "submit";
      hiddenSubmit.hidden = true;
      this.form.appendChild(hiddenSubmit);
    }

    this.form.onsubmit = this.submitForm;
  }

  private addSectionsAndFieldsToForm = (fields: IFields<TFormSchema>) => {
    let sectionHolder: HTMLElement | undefined = undefined;
    let autoFocusOnFirstInput = !!this.formProps?.autoFocusOnFirstInput;

    const setAutofocus = (field: HTMLElement) => {
      field.setAttribute("autofocus", "");
      setTimeout(() => {
        try {
          if (field !== null && field?.focus) field.focus();
        } catch (e) {
          //Do nothing
        }
      }, 100);
    };

    const addNewField = (
      fieldData: IField<TFormSchema> | undefined,
    ): HTMLElement | undefined => {
      if (!fieldData) return undefined;

      const field = createFormFieldAndState({
        fieldProps: fieldData,
        formProps: this.formProps,
        formControlsByName: this.formControlsByName,
      });

      if (!field?.formField || !field?.stateRecord) return undefined; // -->>> Fast Return!

      if (fieldData.type === "title" && fieldData.createSection) {
        sectionHolder = document.createElement("section");
        this.form.appendChild(sectionHolder);
      }

      this.formControls.push(field.stateRecord);

      if (
        field.stateRecord.name &&
        field.stateRecord.type !== "title" &&
        field.stateRecord.type !== "node"
      ) {
        this.formControlsByName[field.stateRecord.name] = field.stateRecord;

        // Event listeners begin ---------------------------------
        field.formField.addEventListener(
          "sl-change",
          this.onChangeValue,
          false,
        );

        if ((field.stateRecord as any)?.fieldProps?.inputListener) {
          field.formField.addEventListener("sl-input", (ev) => {
            this.onChangeValue(ev);
          });
        }

        if (field.stateRecord.type === "numberWithUnits") {
          (field.formField as HTMLInputElement).addEventListener(
            "keyup",
            (ev: KeyboardEvent) => {
              if (ev.key === "Enter") this.submitForm({} as SubmitEvent);
            },
            false,
          );
        }
        // Event listeners end -----------------------------------
      }

      if (
        autoFocusOnFirstInput &&
        (field.stateRecord.type === "textBox" ||
          field.stateRecord.type === "textArea" ||
          field.stateRecord.type === "number" ||
          field.stateRecord.type === "numberWithUnits")
      ) {
        autoFocusOnFirstInput = false;
        setAutofocus(field.formField);
      }

      return field.formField;
    };

    fields.forEach((fA) => {
      if (fA === undefined) return;
      // Row holder
      const rowHolder = document.createElement("div");

      if (Array.isArray(fA)) {
        fA.forEach((fB) => {
          const fieldNode = addNewField(fB);
          if (fieldNode) rowHolder.appendChild(fieldNode);
        });
      } else {
        const fieldNode = addNewField(fA);
        if (fieldNode) rowHolder.appendChild(fieldNode);
      }

      rowHolder.className = `form-fields-row ${
        !Array.isArray(fA) ? fA.type : ""
      } ${
        (Array.isArray(fA)
          ? fA.map((fB) => fB?.holderClassName || "").join(" ")
          : fA.holderClassName) || ""
      }`;

      if (sectionHolder) sectionHolder.appendChild(rowHolder);
      else this.form.appendChild(rowHolder);
    });
  };

  private onChangeValue = (ev: Event) => {
    const target = ev.target as HTMLInputElement;
    const value = target.value;
    const name = target.id as keyof TFormSchema;

    const stateRecord = this.formControlsByName[name];

    if (!stateRecord) return;

    stateRecord.value = getValue(stateRecord, value);

    if (this.hasSubmittedAlready) {
      this.execValidation([], false);
    }

    if (this.onDataChange) {
      this.onDataChange(this.getValues(), name);
    }
  };

  private submitForm = (ev: SubmitEvent) => {
    if (ev.preventDefault) ev.preventDefault();

    this.doSubmitForm();

    return false;
  };

  public setFields(fields: IFields<TFormSchema>) {
    removeChildren(this.form);
    this.addSectionsAndFieldsToForm(fields);
    return this;
  }

  public setFormValidator(newValidator: Schema<TFormSchema>) {
    this.formValidator = newValidator;
    return this;
  }

  public doSubmitForm = ():
    | z.SafeParseSuccess<TFormSchema>
    | z.SafeParseError<TFormSchema> => {
    this.hasSubmittedAlready = true;

    const validationResult = this.execValidation();

    if (validationResult.success) {
      const data: TFormSchema = {
        ...this.hiddenData,
        ...validationResult.data,
      };
      this.onSubmit(data);
    }

    return validationResult;
  };

  private markValidFields(
    validationResult:
      | z.SafeParseSuccess<TFormSchema>
      | z.SafeParseError<TFormSchema>,
    goToNextError = true,
  ) {
    const validationResultError =
      validationResult as z.SafeParseError<TFormSchema>;

    const issues = validationResultError.error
      ? validationResultError.error.issues
      : [];

    const issuesByPath: { [name: string]: ZodIssue } = issues.reduce(
      (acc, v) => {
        const n = String(v.path[0]);
        acc[n] = v;
        return acc;
      },
      {} as { [name: string]: ZodIssue },
    );

    const fieldNames = Object.keys(
      this.formControlsByName,
    ) as (keyof TFormSchema)[];
    let firstError: HTMLElement | undefined = undefined;

    fieldNames.forEach((fc) => {
      const formControl = this.formControlsByName[fc];
      const field = formControl?.field;

      if (formControl && field) {
        const issue = issuesByPath[formControl.name as string];
        if (issue) {
          // has error
          if (!field.classList.contains("has-error"))
            field.classList.add("has-error");

          if (issue.code === z.ZodIssueCode.custom) {
            if ((field as any).helpText !== undefined) {
              (field as any).helpText = issue.message;
            }
          }

          if (!firstError && formControl.type !== "file") firstError = field;
        } else {
          // no error
          field.classList.remove("has-error");
          if ((field as any).helpText) {
            const originalHelpText = field.dataset.helpText;
            (field as any).helpText = originalHelpText;
          }
        }
      }
    });

    if (firstError && goToNextError) {
      const field = firstError as HTMLInputElement;
      const formControl = this.formControlsByName[field.id];
      field.focus();

      if (
        formControl &&
        (formControl.type === "number" ||
          formControl.type === "numberWithUnits" ||
          formControl.type === "checkbox" ||
          formControl.type === "textBox") &&
        !(formControl.props as any).inputListener
      ) {
        field.select();
      }
    }
  }

  public getFormControlsByName(): ITedivoFormRecordOfControlByName<TFormSchema> {
    return {
      ...this.formControlsByName,
    };
  }

  public getAllFormNodes(): ITedivoFormRecord<TFormSchema>[] {
    return this.formControls;
  }

  public getValues(): TFormSchema {
    const fieldNames = Object.keys(
      this.formControlsByName,
    ) as (keyof TFormSchema)[];
    const values: IValuesByName<TFormSchema> = fieldNames.reduce((acc, v) => {
      const st = this.formControlsByName[v];
      if (!st) return acc;
      acc[v] = getValue(st, st.value as any);
      return acc;
    }, {} as IValuesByName<TFormSchema>);
    return values as TFormSchema;
  }

  public setValue(key: keyof TFormSchema, value: string | number | undefined) {
    const field = this.formControlsByName[key];

    if (field) {
      const defaultValue = (field.props as any).defaultValue;
      const finalValue = value ?? defaultValue;
      field.value = finalValue;
      (field.field as any).value = finalValue;
    } else {
      if (this.hiddenData !== undefined && this.hiddenData?.[key] !== undefined)
        this.hiddenData = {
          ...this.hiddenData,
          [key]: value,
        };
    }
    return this;
  }

  public focusOnFirstElement = (delay = 500) => {
    const fieldNames = Object.keys(
      this.formControlsByName,
    ) as (keyof TFormSchema)[];

    if (!fieldNames.length) return;

    const formControl = this.formControlsByName[fieldNames[0]];
    const field = formControl?.field;

    if (field) {
      if (delay) {
        setTimeout(() => field.focus(), delay);
      } else {
        field.focus();
      }
    }
  };

  /** Execs validation over all fields but marks ALL or ONLY THOSE passed as argument */
  public execValidation(
    fields: (keyof TFormSchema)[] = [],
    goToNextError = true,
  ): z.SafeParseReturnType<TFormSchema, TFormSchema> {
    const mergedValues = {
      ...this.hiddenData,
      ...this.getValues(),
    };

    const validationResult = this.formValidator.safeParse(mergedValues);

    if (this.logValidationResult)
      console.log({
        hidden: this.hiddenData,
        values: mergedValues,
        validationResult,
      });

    // --- All fields
    if (fields.length === 0) {
      this.markValidFields(validationResult, goToNextError);
      return validationResult; // -->>> Fast Return!
    }

    // --- Only some fields.
    // A) All is ok
    if (validationResult.success) {
      return validationResult; // -->>> Fast Return!
    }

    // B) Some fields are wrong
    const error = validationResult.error;
    // B.1) Select only those errors that are in the fields array
    const subsetErrors = error.errors.reduce((acc, issue) => {
      const isChecked = issue.path.some(
        (p) => fields.indexOf(p as string) >= 0,
      );

      if (isChecked) acc.push(issue);
      return acc;
    }, [] as z.ZodIssue[]);

    // B.2) Mark only those fields
    this.markValidFields(
      {
        ...validationResult,
        error: {
          ...error,
          errors: subsetErrors,
        } as z.ZodError<TFormSchema>,
      },
      goToNextError,
    );

    return validationResult;
  }
}

function isElementContainedInParent(e: HTMLElement, parent: HTMLElement) {
  let p = e.parentElement;
  while (p !== null) {
    if (p === parent) return true;
    p = p.parentElement;
  }
  return false;
}

export function createSlOption({
  value,
  label,
  disabled,
}: ISlOption): SlOption {
  const opt = document.createElement("sl-option") as SlOption;
  opt.value = String(value);
  opt.innerHTML = label;
  if (disabled) opt.disabled = true;
  return opt;
}

function getValue<TFormSchema extends Record<string, any>>(
  stateRecord: ITedivoFormRecordOfControl<TFormSchema>,
  value: IFieldTypesValues | undefined,
) {
  if (stateRecord.type === "number" || stateRecord.type === "numberWithUnits") {
    if (value === undefined || value === "") {
      return undefined;
    } else {
      return Number(value);
    }
  } else if (stateRecord.type === "date") {
    if (value === undefined || value === "") {
      return undefined;
    } else if (typeof value === "string") {
      return new Date(value);
    } else {
      return value;
    }
  } else if (stateRecord.type === "checkbox") {
    if (value === undefined || value === "") {
      return undefined;
    } else if ((stateRecord as any).isNumericEnum) {
      return value ? 1 : 0;
    } else {
      return Boolean(value);
    }
  } else {
    return value;
  }
}
